// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../fonts/GilroyBold/GilroyBold.woff2";
import ___CSS_LOADER_URL_IMPORT_1___ from "../../fonts/GilroyMedium/GilroyMedium.woff2";
import ___CSS_LOADER_URL_IMPORT_2___ from "../../fonts/GilroyRegular/GilroyRegular.woff2";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n    font-family: 'GilroyBold';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\");\n    font-display: swap;\n    font-weight: normal;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'GilroyMedium';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff2\");\n    font-display: swap;\n    font-weight: normal;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'GilroyRegular';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\");\n    font-display: swap;\n    font-weight: normal;\n    font-style: normal;\n}\n", "",{"version":3,"sources":["webpack://./src/v1/fonts.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,4DAAmE;IACnE,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,2BAA2B;IAC3B,4DAAuE;IACvE,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,4BAA4B;IAC5B,4DAAyE;IACzE,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":["@font-face {\n    font-family: 'GilroyBold';\n    src: url(\"../../fonts/GilroyBold/GilroyBold.woff2\") format(\"woff2\");\n    font-display: swap;\n    font-weight: normal;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'GilroyMedium';\n    src: url(\"../../fonts/GilroyMedium/GilroyMedium.woff2\") format(\"woff2\");\n    font-display: swap;\n    font-weight: normal;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'GilroyRegular';\n    src: url(\"../../fonts/GilroyRegular/GilroyRegular.woff2\") format(\"woff2\");\n    font-display: swap;\n    font-weight: normal;\n    font-style: normal;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
