export const CHANGE_TAB = 'CHANGE_TAB';

export const SET_SELECTED_PAGE = 'SET_SELECTED_PAGE';
export const SET_DEFAULT_SELECTED_PAGES = 'SET_DEFAULT_SELECTED_PAGES';

export const SET_SELECTED_VIEW_MODE = 'SET_SELECTED_VIEW_MODE';

export const SET_SELECTED_FILTER = 'SET_SELECTED_FILTER';
export const SET_DEFAULT_SELECTED_FILTERS = 'SET_DEFAULT_SELECTED_FILTERS';
export const LOAD_FROM_LOCAL_STORAGE_SELECTED_FILTERS = 'LOAD_FROM_LOCAL_STORAGE_SELECTED_FILTERS';
