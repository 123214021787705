export const TITLE = 'FAQ';
export const TITLES = [
  'Зачем ещё один скалолазный трекер?',
  'Кто вносит трассы?',
  'Где мой любимый скалодром? Почему его нет в списке?',
  'Что за неработающий раздел «Скалы»?',
  'У вас нет трассы, которую я пролез. Могу я сам внести её?',
  'Я придумал суперскую трассу, могу я опубликовать её у вас?',
];
export const FAQ_DATA = [
  [
    'Ключевое отличие этого ресурса от всех предыдущих: он изначально задумывался и разрабатывался не для заработка денег, а для удовлетворения собственных скалолазных потребностей. Авторы ресурса - сами скалолазы и им надоело смотреть, как очередная инкарнация Инстаклаймба появляется и исчезает в небытии со всеми данными, которые мы туда кропотливо вносим.',
    'Кроме того, ввиду исключительно коммерческих целей всех предыдущих ресурсов, они не обладали некоторыми важными для нас функциями. Например, аналитикой по длительной истории затреканых пролазов. У нас её тоже пока нет, но она в нашем ближайшем TODO-листе.'
  ],
  [
    'RC - общественный ресурс. Все наполнение ведется его участниками. К сожалению, очевидный вариант - договориться с накрутчиками - пока не работает: у них своих забот хватает. В данный момент внесение трасс - самое тяжкое бремя, которое лежит на инициативной группе RC. Поэтому мы будем очень благодарны всем, кто сможет взять на себя кусочек этого бремени хотя бы на время. Пишите нам в соцсети.',
  ],
  [
    'RC - общественный ресурс. Каждый может стать его активным участником и начать вносить трассы для того скалодрома, который ему нравится (даже если он находится не в Москве). Если вы хотите стать ответственным за какой-нибудь скалодром, которого пока нет в нашем списке, пишите нам в соцсети, мы горячо вас поддержим.',
  ],
  [
    'Да-да. В некотором светлом будущем мы планируем выйти за пределы скалодромов и охватить природные скалы в том числе. Но это работа совсем другого масштаба, поэтому пока мы лишь рекламируем это намерение с целью узнать мнение людей относительно этой идеи. Если вам есть, что сказать - пишите в соцсети.',
  ],
  [
    'Если вы выберете конкретный зал на скалодроме (большой черной кнопкой с белой стрелкой), то в мобильной версии у вас появится кнопка добавления трассы. В десктопной версии для её появления надо зажать клавишу Ctrl. Добавленная таким образом трасса будет вашей личной. Вы можете воспользоваться функцией обратной связи (восклицательный знак) на странице просмотра вашей трассы, чтобы сообщить нам, что мы упустили эту трассу. Мы проверим ваше сообщение и, если все в порядке, переведем трассу из личных в общественные. А ещё мы скажем вам большое спасибо за помощь!',
  ],
  [
    'В ответе на предыдущий вопрос описано, как добавить личную трассу (они у нас называются "авторскими"). Ссылку на эту трассу вы можете кидать своим друзьям, они смогут её открыть.',
  ],
];
